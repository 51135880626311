import { defineStore } from 'pinia'

const key = 'useDisplayStore'

export const useDisplayStore = defineStore(key, () => {

  const windowWidth = ref(window.innerWidth)
  
  const sm = computed<boolean>(() => windowWidth.value >= 640)
  const md = computed<boolean>(() => windowWidth.value >= 768)
  const lg = computed<boolean>(() => windowWidth.value >= 1024)
  const xl = computed<boolean>(() => windowWidth.value >= 1280)
   
  onMounted(() => {
    if(typeof window !== 'undefined'){
      windowWidth.value = window.innerWidth
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
      });
    }
  })

  return { sm, md, lg, xl }
  
})